@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* https://freecodez.com */
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
/* body {
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
  background: radial-gradient(circle, #0077ea, #1f4f96, #1b2949, #000);
	height: 100vh;
	position: relative;
} */
.stage {
  height: 100%;
  width: 100%;
  margin: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  perspective: 9999px;
  transform-style: preserve-3d;
}
.layer {
  width: 100%;
  height: 100%;
  position: absolute;
  transform-style: preserve-3d;
  animation: ಠ_ಠ 5s infinite alternate ease-in-out -7.5s;
  animation-fill-mode: forwards;
  transform: rotateY(40deg) rotateX(33deg) translateZ(0);
}
.layer:after {
  font-family: 'Pacifico', 'Kaushan Script', Futura, 'Roboto', 'Trebuchet MS', Helvetica, sans-serif;
  font-size: clamp(4rem, 10vw, 3rem);
  content: var(--text);
  white-space: pre;
  text-align: center;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  color: whitesmoke;
  letter-spacing: -2px;
  text-shadow: 4px 0 10px rgba(0, 0, 0, 0.13);
}
.layer:nth-child(1):after {
  transform: translateZ(0px);
}
.layer:nth-child(2):after {
  transform: translateZ(-1.5px);
}
.layer:nth-child(3):after {
  transform: translateZ(-3px);
}
.layer:nth-child(4):after {
  transform: translateZ(-4.5px);
}
.layer:nth-child(5):after {
  transform: translateZ(-6px);
}
.layer:nth-child(6):after {
  transform: translateZ(-7.5px);
}
.layer:nth-child(7):after {
  transform: translateZ(-9px);
}
.layer:nth-child(8):after {
  transform: translateZ(-10.5px);
}
.layer:nth-child(9):after {
  transform: translateZ(-12px);
}
.layer:nth-child(10):after {
  transform: translateZ(-13.5px);
}
.layer:nth-child(11):after {
  transform: translateZ(-15px);
}
.layer:nth-child(12):after {
  transform: translateZ(-16.5px);
}
.layer:nth-child(13):after {
  transform: translateZ(-18px);
}
.layer:nth-child(14):after {
  transform: translateZ(-19.5px);
}
.layer:nth-child(15):after {
  transform: translateZ(-21px);
}
.layer:nth-child(16):after {
  transform: translateZ(-22.5px);
}
.layer:nth-child(17):after {
  transform: translateZ(-24px);
}
.layer:nth-child(18):after {
  transform: translateZ(-25.5px);
}
.layer:nth-child(19):after {
  transform: translateZ(-27px);
}
.layer:nth-child(20):after {
  transform: translateZ(-28.5px);
}
.layer:nth-child(n + 10):after {
  -webkit-text-stroke: 3px rgba(0, 0, 0, 0.25);
}
.layer:nth-child(n + 11):after {
  -webkit-text-stroke: 15px dodgerblue;
  text-shadow: 6px 0 6px #00366b, 5px 5px 5px #002951, 0 6px 6px #00366b;
}
.layer:nth-child(n + 12):after {
  -webkit-text-stroke: 15px #0077ea;
}
.layer:last-child:after {
  -webkit-text-stroke: 17px rgba(0, 0, 0, 0.1);
}
.layer:first-child:after {
  color: #fff;
  text-shadow: none;
}
@keyframes ಠ_ಠ {
  100% {
    transform: rotateY(-40deg) rotateX(-43deg);
  }
}


.animate-charcter
{
   text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
      font-size: 190px;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}


*{
  padding: 0;
  margin: 0;
}
body{
  background-color: #000;
}
.center{
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.center h1{
  color: rgba(255,0,0,0.1);
  font-size: 50px;
  text-transform: uppercase;
  font-weight: 700;
  background-size: cover;
  background-image: url(https://cdn-images-1.medium.com/max/2000/1*Jalb56N34pBIGCjQULtW3A.jpeg);
  -webkit-background-clip: text;
  animation: background-text-animation 15s linear infinite;
}
@keyframes background-text-animation {
  0%{
    background-position: left 0px top 50%;
  }
  50%{
    background-position: left 1500px top 50%;
  }
  100%{
    background-position: left 0px top 50%;
  }
}

p {
  letter-spacing: 1px;
  word-spacing: 3px;
}

/* From Uiverse.io by DavidTM96 */ 
.form {
  display: flex;
  flex-direction: column;
  align-self: center;
  font-family: inherit;
  gap: 10px;
  padding-inline: 2em;
  padding-bottom: 0.4em;
  background-color: #171717;
  /* background-color: #0a192f; */
  border-radius: 20px;
}

.form-heading {
  text-align: center;
  margin: 2em;
  color: #64ffda;
  font-size: 1.2em;
  background-color: transparent;
  align-self: center;
}

.form-field {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  border-radius: 10px;
  padding: 0.6em;
  border: none;
  outline: none;
  color: white;
  background-color: #171717;
  box-shadow: inset 2px 5px 10px rgb(5, 5, 5);
}

.input-field {
  background: none;
  border: none;
  outline: none;
  width: 100%;
  color: #ccd6f6;
  padding-inline: 1em;
}

.sendMessage-btn {
  cursor: pointer;
  margin-bottom: 3em;
  padding: 1em;
  border-radius: 10px;
  border: none;
  outline: none;
  background-color: transparent;
  color: #64ffda;
  font-weight: bold;
  outline: 1px solid #64ffda;
  transition: all ease-in-out 0.3s;
}

.sendMessage-btn:hover {
  transition: all ease-in-out 0.3s;
  background-color: #64ffda;
  color: #000;
  cursor: pointer;
  box-shadow: inset 2px 5px 10px rgb(5, 5, 5);
}

.form-card1 {
  background-image: linear-gradient(163deg, #64ffda 0%, #64ffda 100%);
  border-radius: 22px;
  transition: all 0.3s;
}

.form-card1:hover {
  box-shadow: 0px 0px 30px 1px rgba(100, 255, 218, 0.3);
}

.form-card2 {
  border-radius: 0;
  transition: all 0.2s;
}

.form-card2:hover {
  transform: scale(0.98);
  border-radius: 20px;
}


.cardq {
  display: flex;
  height: 75px; /* Increased height to accommodate larger icons */
  width: 400px; /* Adjusted width for spacing between icons */
}

/* Specific for Center Alignment */
.cardq-center {
  justify-content: center;
}

/* Specific for Left Alignment */
.cardq-left {
  justify-content: flex-start;
}

.cardq svg {
  position: absolute;
  display: flex;
  width: 60%; /* Increased the size of the icons */
  height: 100%;
  font-size: 36px; /* Increased icon size */
  font-weight: 700;
  opacity: 1;
  transition: opacity 0.25s;
  z-index: 2;
  cursor: pointer;
}

.cardq .social-link1, .cardq .social-link2, .cardq .social-link3, .cardq .social-link4, .cardq .social-link5, .cardq .social-link6, .cardq .social-link7 {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px; /* Set fixed width for the circular icons */
  height: 50px; /* Set fixed height for the circular icons */
  margin: 0 5px; /* Added margin for spacing between icons */
  color: whitesmoke;
  font-size: 36px; /* Increased icon size */
  text-decoration: none;
  transition: 0.25s;
  border-radius: 50%; /* Makes the background a perfect circle */
}

/* Set the background colors permanently */
.cardq .social-link1 {
  background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
}

.cardq .social-link2 {
  background-color: #0077b5; /* LinkedIn */
}

.cardq .social-link3 {
  background-color: #333; /* GitHub */
}

.cardq .social-link4 {
  background-color: #0088cc; /* Telegram */
}

.cardq .social-link5 {
  background-color: #5865f2; /* Discord */
}

.cardq .social-link6 {
  background-color: #12a50b; /* WhatsApp */
}

.cardq .social-link7 {
  background-color: #1877f2; /* Facebook */
}

/* Bounce animation on hover */
.cardq .social-link1:hover,
.cardq .social-link2:hover,
.cardq .social-link3:hover,
.cardq .social-link4:hover,
.cardq .social-link5:hover,
.cardq .social-link6:hover,
.cardq .social-link7:hover {
  animation: bounce_613 0.4s linear;
}

@keyframes bounce_613 {
  40% {
    transform: scale(1.4);
  }
  60% {
    transform: scale(0.8);
  }
  80% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}


/* From Uiverse.io by JaydipPrajapati1910 */ 
.cardd {
  width: 600px;
  height: 254px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  gap: 10px;
  padding: 0px 15px;
  background-color: red;
  border-radius: 50px;
  border: none;
  color: white;
  position: relative;
  cursor: pointer;
  font-weight: 900;
  transition-duration: .2s;
  background: linear-gradient(0deg, #000, #272727);
}

.cardd:before, .cardd:after {
  content: '';
  position: absolute;
  left: -2px;
  top: -2px;
  border-radius: 50px;
  background: linear-gradient(45deg, #fb0094, #0000ff, #00ff00,#ffff00, #ff0000, #fb0094, 
		#0000ff, #00ff00,#ffff00, #ff0000);
  background-size: 400%;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  z-index: -1;
  animation: steam 20s linear infinite;
}

@keyframes steam {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}

.cardd:after {
  filter: blur(10px);
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

body {
  /* display: flex; */
  /* background: #333; */
  justify-content: flex-end;
  align-items: flex-end;
  /* min-height: 100vh; */
}

.footer {
  position: relative;
  width: 100%;
  background: #3586ff;
  min-height: 100px;
  padding: 20px 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.social-icon,
.contact-info {
  position: relative;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  margin: 10px 0;
  flex-wrap: wrap;
}

.social-icon__item,
.contact-info__item {
  list-style: none;
  margin: 10px;
}

.social-icon__link {
  font-size: 2rem;
  color: #fff;
  margin: 0 10px;
  display: inline-block;
  transition: 0.5s;
}

.social-icon__link:hover {
  transform: translateY(-10px);
}

.contact-info__item {
  font-size: 1.2rem;
  color: #fff;
  margin: 0 10px;
  opacity: 0.75;
}

.contact-info__item a {
  color: #fff;
  text-decoration: none;
  transition: 0.5s;
}

.contact-info__item a:hover {
  opacity: 1;
}

.footer p {
  color: #fff;
  margin: 15px 0 10px 0;
  font-size: 1rem;
  font-weight: 300;
}

.wave {
  position: absolute;
  top: -100px;
  left: 0;
  width: 100%;
  height: 100px;
  background: url("https://i.ibb.co/wQZVxxk/wave.png");
  background-size: 1000px 100px;
}

.wave#wave1 {
  z-index: 1000;
  opacity: 1;
  bottom: 0;
  animation: animateWaves 4s linear infinite;
}

.wave#wave2 {
  z-index: 999;
  opacity: 0.5;
  bottom: 10px;
  animation: animate 4s linear infinite !important;
}

.wave#wave3 {
  z-index: 1000;
  opacity: 0.2;
  bottom: 15px;
  animation: animateWaves 3s linear infinite;
}

.wave#wave4 {
  z-index: 999;
  opacity: 0.7;
  bottom: 20px;
  animation: animate 3s linear infinite;
}

@keyframes animateWaves {
  0% {
    background-position-x: 1000px;
  }
  100% {
    background-position-x: 0px;
  }
}

/* @keyframes animate {
  0% {
    background-position-x: -1000px;
  }
  100% {
    background-position-x: 0px;
  }
} */

